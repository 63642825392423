<template>
	<div class="aboutUs-box">
        <vheader
        :navId=2
        ></vheader>
		<!-- banner组件 -->
		<banner 
		:navName="navName"
		:navUrl="navUrl"
		></banner>
		<!-- banner组件 -->
		<div class="aboutUs-content">            
			<div class="title05">
				<h1>ABOUT US</h1>
				<h2>公司简介</h2>
			</div>
			<div class="box01">
				<div class="left01"><div></div></div>
				<div class="right01">
					<p>&emsp;&emsp;北京恒安康泰医疗健康科技有限公司（简称“恒安康泰医疗健康科技”，英文全称“HenganMedicare”）是一家综合型医疗咨询服务及健康管理公司，在2020年突发新型冠状肺炎疫情的大背景下，我公司经过股份整合，成立北京恒安康泰医疗健康科技有限公司，公司注册资本1000万。</p>
					<p>&emsp;&emsp;恒安康泰医疗健康科技，聚焦我国大众医疗重点领域，通过与北京医疗健康各合作方的高效连接，持续打造精准、细致、便捷的赴京就医新生态体系。致力于构建“赴京就医全方位医疗保障平台”，为全国各地的患者，尤其是企事业在职员工提供优质，全面，深入人心的赴京就医诊疗和健康管理服务。</p>
					<p>&emsp;&emsp;公司凭借国内领先的医疗信息管理、健康知识管理、疾病分析管理等行业经验，针对企事业单位职工个人用户，利用“互联网+”手段，构建“恒安康泰医疗一路通”APP，成为提供便捷赴京就医、个人医疗健康管理、企事业人私人医疗健康保障的综合服务线上平台，为企事业人及大众赴京看病就医、日常健康咨询管理等提供便利。</p>
					<p>&emsp;&emsp;恒安康泰医疗健康科技以智慧就医保障为核心，健康咨询和医疗行程服务为辅助，打造企事业人和大众的智慧就医一体化平台，全面赋能大众健康生活。同时，积极延伸至为卫生、医院、药企、养生、健康动态生活产业链赋能。此外，公司可为合作伙伴提供专业赴京医疗就诊咨询服务，特色医院推荐服务，并为企事业人和大众提供便捷、精准、安全、优质的创新型健康检测服务。</p>
				</div>
			</div>            
            <ul class="box02">
                <li v-for="item in box03List" :key="item.id">
                    <p>
                        <span class="box03-p01">{{item.listNum}}</span>
                        <span class="box03-p02">&nbsp;{{item.listSymbol}}</span>
                    </p>
                    <p class="box03-p03">{{item.listWord}}</p>
                </li>
            </ul>                 
			<div class="title05">
				<h1>OUR TEAM</h1>
				<h2>研发团队</h2>
			</div>
            <div class="box03">
                <p>公司拥有一支高素质、专业化的研发队伍，核心骨干均为业内资深工程师。团队不断突破核心技术，形成了产品规划、技术研发、测试与应用、技术支持、运行维护等合理布局，公司凭借强大的研发实力，致力于为客户提供优质的产品和服务。</p>
                <div class="img03">
                    <img src="../../assets/img/aboutUs/au03.png" alt="研发团队">
                    <img src="../../assets/img/aboutUs/au02.png" alt="研发团队">
                </div>
            </div>                        
			<div class="title05">
				<h1>CERTIFICATE</h1>
				<h2>资质证书</h2>
			</div>
            		
			<div class="box06">
			<div class="box06-content">			
				<div class="img06">
					<img v-for="item in zhengshuList" :key="item.index" :src="item.imgUrl" alt="媒体报道">
					<img v-for="item in zhengshuList" :key="item.index" :src="item.imgUrl" alt="媒体报道"> 
					 <img v-for="item in zhengshuList" :key="item.index" :src="item.imgUrl" alt="媒体报道">
				</div>
			</div>			
		    </div>		
        </div>
        <vfooter></vfooter>
        <GoTop/>
    </div>
</template>

<script>
import banner from '../../components/banner.vue'
import vheader from '../../components/vheader.vue'
import vfooter from '../../components/vfooter.vue'
import GoTop from '@/components/GoTop.vue'
export default { 
	name: 'aboutUs',
	data () {
		return{
			navName:'公司简介',
			navUrl:'aboutUs',      
			box03List:[
				{listNum:'10',listSymbol:'余年',listWord:'行业经验'},
				{listNum:'100',listSymbol:'+',listWord:'合作企业'},
				{listNum:'20',listSymbol:'+',listWord:'服务领域'},
				{listNum:'5',listSymbol:'+',listWord:'分公司'},
				{listNum:'113.5',listSymbol:'万',listWord:'持证服务'},
			],   
            zhengshuList:[
                {imgUrl:require('../../assets/img/aboutUs/au04.jpg')},
                {imgUrl:require('../../assets/img/aboutUs/au05.jpg')},
                {imgUrl:require('../../assets/img/aboutUs/au06.jpg')},
                {imgUrl:require('../../assets/img/aboutUs/au07.jpg')},
                {imgUrl:require('../../assets/img/aboutUs/au08.jpg')},
                {imgUrl:require('../../assets/img/aboutUs/au09.jpg')},
                {imgUrl:require('../../assets/img/aboutUs/au010.jpg')},
                {imgUrl:require('../../assets/img/aboutUs/au011.jpg')},
            ]
        }
	},
	props: {
	},
	methods:{		
		
	},
	components:{
		banner,
		vheader,
		vfooter,
        GoTop
	},
}
</script>

<style  lang="less">
// pc端样式
@media screen and (min-width:750px){
    .aboutUs-box{
        .aboutUs-content{
            margin: AUTO;
            padding: 37Px 0 0;	
            .title05{
                :first-child{
                    font-size: 42Px;
                    color: #ddd;
                    text-align: center;
                }
                :nth-child(2){
                    width: 89Px;
                    margin:0 auto 30Px;
                    font-size: 22Px;
                    padding-bottom: 13Px;
                    color: #222;
                    border-bottom: 1Px solid #093C6C;
                }
            }
            .box01{
                width: 1200Px;
                margin: auto;
                display: flex;
                justify-content: space-between;
                .left01{
                    width: 470Px;
                    height: 370Px;
                    background: url(../../assets/img/index/box03_01.jpg) no-repeat center / 120%;
                    div{
                    width: 470Px;
                    height: 370Px;
                    transition: all 0.3s;
                        &:hover{
                            width: 470Px;
                            height: 370Px;                        
                            background:#000 url(../../assets/img/aboutUs/au01.png) no-repeat center ;
                            opacity: 0.6;                            
                        }
                        
                    }
                }
                .right01{
                    width: 670Px;
                    height: 370Px;
                    overflow: auto;
                    color: #666;
                    font-size: 16Px;
                    line-height: 35Px;
                    padding-right: 15Px;
                        &:hover{
                            &::-webkit-scrollbar-thumb {
                                background-color: #555; 
                                /*滚动条里面小方块*/                            
                            }     
                            &::-webkit-scrollbar-track {
                                /*滚动条里面轨道*/
                                background   : #ddd;
                            }                        
                        }
                    p{
                        margin-bottom: 15Px;
                    }
                    &::-webkit-scrollbar {                
                        /*滚动条整体样式*/
                        width : 5Px;  /*高宽分别对应横竖滚动条的尺寸*/
                    }
                    &::-webkit-scrollbar-thumb {
                        /*滚动条里面小方块*/
                        width: 10Px;
                        border-radius: 10px;
                        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
                        background   : #888;
                    }
                    &::-webkit-scrollbar-track {
                        /*滚动条里面轨道*/
                        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
                        border-radius: 10px;
                        background   : #eee;
                    }  


                }
            }            
            .box02{
                width: 1200Px;
                display: flex;
                justify-content: space-between;
                margin: 55Px auto;                
                li{
                    border-right: 1Px solid #D7D7D7;
                    color: #666;
                    padding: 10Px 75Px 10Px 0;
                    font-size: 18Px;	
                    &:first-child{
                        padding-left: 75Px ;
                    }
                    &:last-child{
                        border: 0;
                    }
                    .box03-p01{
                        font-size: 48Px;
                        font-weight: 700;
                        color: #093c6c;
                    }
                    .box03-p03{
                        text-align: center;
                    }
                }
            }
            .box03{
                width: 1200Px;
                margin:0 auto 60Px;
                font-size: 15Px;
                color: #434343;
                text-align: center;
                line-height: 30Px;
                .img03{
                    margin-top: 35Px;
                    display: flex;
                    justify-content: space-between;
                    img{
                        border-radius: 10Px;
                        transition: all 0.2s;
                        &:hover{
                            box-shadow: 0 0 15Px #aaa;
                        }

                    }
                }
            }
            .box06{
			    background: url(../../assets/img/index/box06_01.jpg) no-repeat center / 100%;
                .box06-content{
                    width: 1200Px;
                    overflow: hidden;
                    margin: auto;
                    padding: 70Px 0;
                    .img06{
                        display: flex;
                        animation: img06anim infinite linear 12s;
                        justify-content: space-between;
                            &:hover{
                                animation-play-state:paused;
                            }
                        img{
                            margin-right: 24Px;
                        }
                        @keyframes img06anim {
                            100%{
                                margin-left: -760Px;
                            }
                        }
                    }
                }	
			}		
        }
    }
    
        
}

// 移动端样式
@media screen and (max-width:750px){
    .aboutUs-box{
        .aboutUs-content{
            .title05{
				width: 95%;
                margin-top: 10px;
				:first-child{
					font-size: 25px;
					color: #ddd;
					text-align: center;
				}
				:nth-child(2){
					width: 65px;
					font-size: 16px;
					color: #222;
					margin:0 auto 12px;
					padding-bottom: 5px;
					border-bottom: 1px solid #093C6C;
				}
			}
            .box01{
                width: 95%;
                margin: auto;
                .left01{
                    width: 100%;
                    height: 240px;
                    border-radius: 7px;
                    background: url(../../assets/img/index/box03_01.jpg) no-repeat center / 120%;                    
                }
                .right01{
                    width: 97%;
                    height: 240px;
                    overflow: auto;
                    margin: 10px 0 30px;
                    color: #666;
                    font-size: 14px;
                    line-height: 30px;
                    padding-right: 10px;
                    p{
                        margin-bottom: 10px;
                    }
                    &::-webkit-scrollbar {                
                        /*滚动条整体样式*/
                        width : 5px;  /*高宽分别对应横竖滚动条的尺寸*/
                    }
                    &::-webkit-scrollbar-thumb {
                        /*滚动条里面小方块*/
                        width: 10px;
                        border-radius: 10px;
                        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
                        background   : #888;
                    }
                    &::-webkit-scrollbar-track {
                        /*滚动条里面轨道*/
                        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
                        border-radius: 10px;
                        background   : #eee;
                    }  
                }
            }            
            .box02{
                display: none;
            }
            .box03{
                width: 95%;
                margin:20px auto 15px;
                font-size: 14px;
                color: #434343;
                line-height: 25px;
                .img03{
                    margin-top: 10px;
                    img{
                        height: 195px;
                        width: 100%;
                    }
                }
            }
            .box06{
			    background: url(../../assets/img/index/box06_01.jpg) no-repeat center / 195%;
                .box06-content{
                    width: 100%;
                    overflow: hidden;
                    margin:23px auto 34px;
                    padding: 50px 0;
                    .img06{
                        height: 210px;
                        display: flex;
                        animation: img06anim infinite linear 12s;
                        img{
                            width: 150px;
                            margin-right: 20px;
                        }
                        @keyframes img06anim {
                            100%{
                                margin-left: -510px;
                            }
                        }
                    }
                }	
			}	
        }
    }
}
</style>
